.propertyFeature {
    /* border: 1px solid #5f5f5f; */
    /* padding: 14px; */
    /* border-radius: 10px; */
    height: auto;
    /* box-shadow: 0 0 10px 1px #adadad96; */
}

.propertyFeature ul {
    padding: 0;
}

.propertyFeature ul li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-right: 20px;
    font-size: 14px;
    justify-content: space-between;
}

.main-room-slider .slick-list {
    padding: 11px 10px 10px 10px;
}

.main-room-slider.cursor-pointer .slick-track {
    padding: 7px;
}

.main-room-slider .slick-slide {
    padding-right: 16px;
}

.main-room-slider .slick-list {
    padding: 10px 10px;
}

.propertyFeature ul li span {
    display: flex;
}

.propertyFeature ul li div {
    display: flex;
    align-items: center;
}

.propertyFeature ul li li span {
    display: flex;
    align-items: center;
    margin-left: auto;
}


.propertyFeature img {
    max-width: 21px;
    margin-right: 10px;
    border-radius: 4px;
    max-height: 29px;
    object-fit: contain;
}

.modal-header {
    border: 0;
    text-align: center;
}

.modal-header h5.modal-title {
    margin: 0 auto;
    display: table;
    width: 100%;
}

.modal-header button {
    position: absolute;
    left: 18px;
    color: #000;
    opacity: 1;
}

.amenitiesCheckbox {
    position: relative;
    appearance: none;
    width: 100px;
    height: 50px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.bedIcon {
    width: 40px;
    height: 40px;
}

.propertyFeature p {
    font-size: 14px;
}

.iconCount {
    position: fixed;

}

.modal-header {
    border: 0;
}

.modal-header h5.modal-title {
    margin: 0 auto;
    display: table;
    width: 100%;
    text-align: center;
}

ul.slick-dots {
    padding-right: 15px;
}

.modal-header button {
    position: absolute;
    opacity: 1;
}

.room {
    max-width: 60px !important;
    height: 45px;
}

.recommendedProperty {
    position: relative;
}

.recommendedProperty img {
    height: 240px;
    object-fit: cover;
    padding: 0px 0px;
    width: 100%;
    border-radius: 15px;
}


.gallery-modal .col-md-2 img {
    width: 100%;
    height: 160px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.gallery-count {
    position: absolute;
    top: 0;
    right: 19px;
    background: #e89532;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    cursor: pointer;
}

.slide-name h4 {
    color: #4e4439 !important;
    font-family: Cambay-Regular, sans-serif !important;
    font-size: 90%;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 5px;
}


.recommendedProperty p {
    position: absolute;
    top: 7px;
    right: 19px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    opacity: 0.5;
    cursor: pointer;
    background: #e89532;
}

.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4e4439;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
}

.amnites li:before {
    content: "";
    position: absolute;
    left: -21px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #4e4439;
}

.amnites {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.amnites li {
    list-style: none;
    margin-right: 39px;
    color: #4e4439;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
}

.aminity-wh-img {
    margin-bottom: 30px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
    grid-gap: 20px 40px;
    background: #f7f7f7;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 20px;
    position: relative;
    /* width: -webkit-max-content;
    width: max-content; */
}

.aminity-wh-img li {
    color: #333;
    font-family: Campton-light;
    font-weight: 700;
    margin: 0;
    position: relative;
    grid-gap: 10px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;
    white-space: nowrap;
    white-space: break-spaces;
    width: 22%;
    font-size: 15px;
    line-height: 1.2;
}

.amnites li img {
    margin-right: 0px;
    max-width: 25px;
}

.other-room {
    grid-template-columns: 1fr 1fr 1fr;
}

.amnites li:first-child::before {
    display: none;
}

.aminity-wh-img li::before {
    display: none;
}


.special-amnitiy {
    color: #e89532 !important;
}

@media (max-width:767px) {
    .aminity-wh-img {
        display: flex;
        width: auto;
    }
    .side-sider
    {
        display: none !important;
    }
    .propertyFeature {
        height: auto;
        width: 100% !important;
    }
    .propertyFeature ul li {
        padding-right: 0;
        font-size: 13px;
        padding-bottom: 8px;
    }
    .propertyFeature {
        padding: 0;
        box-shadow: none;
    }
    .propertyFeature img
    {
        margin-right: 12px !important;
    }
    .aminity-wh-img li {

        width: 100%;
        font-size: 14px;
    }

    .aminity-wh-img li img {
        height: 39px;
        object-fit: contain;
    }

    .aminity-wh-img {
        grid-gap: 3px 12px;
        padding: 0;
        background: transparent;

    }
    .aminity-wh-img li {
        width: 100%;
    }
}